<template>
  <router-link :to="link">
    <div class="profile-link mb-10">
      <div class="flex-2">
        <div class="flex flex-col justify-center space-y-2">
          <h1 class="font-bold text-lg md:text-2xl xl:text-4x">{{ name }}</h1>
          <p class="text-sm" v-if="title">{{ title }}</p>
          <AnchorLink :text="anchorLinkText" :url="link" />
        </div>
      </div>
      <div class="flex-1 h-full" :class="{ '-mb-12': image }">
        <div class="h-full">
          <img class="object-contain" v-if="image" :src="image" />
          <img
            class="object-contain"
            v-else
            src="../../assets/images/nasida-logomark.svg"
          />
        </div>
      </div>
    </div>
  </router-link>
</template>

<style src="./profile-link.component.css" scoped lang="css"></style>
<script src="./profile-link.component.js"></script>
