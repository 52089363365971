import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";

import Hero from "@/components/hero/index.vue";
import ProfileLink from "@/components/profile-link/index.vue";
import heroBg from "../../assets/images/about-us-bg.png";

export default defineComponent({
  name: "Our Governance",
  components: { ProfileLink, Hero },
  setup() {
    const store = useStore();
    const heroSlide = [
      {
        title: "About Us",
        image: heroBg,
      },
    ];

    onMounted(() => {
      store.dispatch("getGovernor");
      store.dispatch("getMDCEO");
    });

    const governor = computed(() => store.state.governorsModule.governor);
    const mdCEO = computed(() => store.state.managementTeamModule.mdCEO);

    return {
      heroSlide,
      governor,
      mdCEO,
    };
  },
});
