import { defineComponent } from "vue";
import AnchorLink from "../anchor-link/index.vue";

export default defineComponent({
  name: "Profile Link",
  components: { AnchorLink },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    link: {
      type: String,
      required: true,
    },
    image: {
      type: String,
    },
  },
  computed: {
    anchorLinkText() {
      if (this.image ||  this.title) {
        return "View Profile";
      } else {
        return "View Profiles";
      }
    },
  },
});
