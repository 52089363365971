<template>
  <div id="our-governance">
    <Hero :slideContent="heroSlide" />
    <div class="governance_body mt-10">
      <div class="mb-16">
        <div class="sub_headings text-2xl">Our Governance</div>
        <div class="mt-6 text-xl">
          View the profiles of our Leadership and Economic Advisory Council
        </div>
      </div>

      <ProfileLink
        v-if="governor"
        :name="governor.name"
        title="The Governor, Nasarawa State "
        link="/governor"
        :image="governor.profilePhoto"
      ></ProfileLink>
      <ProfileLink
        v-if="mdCEO"
        :name="mdCEO.name"
        title="MD/CEO "
        link="/managing-director"
        :image="mdCEO.profilePhoto"
      ></ProfileLink>
      <ProfileLink
        name="Management Team"
        link="/management-team"
      ></ProfileLink>
      <ProfileLink
        name="Economic Advisory Council"
        link="/economic-advisory-council"
      ></ProfileLink>
    </div>
  </div>
</template>

<script src="./our-governance.page.js"></script>
<style src="./our-governance.page.css" scoped lang="css"></style>
